import React, { useState, useEffect } from 'react';
import { Box, Input, Text, Button, useToast, Link, Flex, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { useAuth } from '../../services/AuthenticationContext';
import { useKeyPress } from '../../config/useEnterClick';

const ForgotPasswordForm = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    emailError: false,
  });

  const { loading, resetPassword } = useAuth();
  const toast = useToast();
  const isEnter = useKeyPress('Enter');

  const handleButtonClick = () => {
    const { email } = formValues;
    if (!email) {
      setFormValues({ ...formValues, emailError: true });
      return toast({
        position: 'top-right',
        title: 'Fehler',
        description: 'Bitte geben Sie eine E-mailadresse an!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    resetPassword(email);
  };

  const handleEmailChange = ({ target: { value } }) => setFormValues({ ...formValues, emailError: false, email: value });

  useEffect(() => {
    if (isEnter) {
      handleButtonClick();
    }
  }, [isEnter]);

  return (
    <Box
      padding="16px"
      backgroundColor="white"
      borderTopLeftRadius="5px"
      borderBottomLeftRadius="5px"
      mt="32px"
      mb="32px"
      boxShadow="0px 10px 7px 2px #ab1b1d"
    >
      <Text mb="16px">
        Bitte geben Sie Ihre Mailadresse ein. Wir senden Ihnen einen Link zu, mit dem Sie ein neues Passwort erzeugen können.
      </Text>
      <InputGroup>
        <InputLeftElement>
          <EmailIcon color="gray.400" />
        </InputLeftElement>
        <Input
          variant="outline"
          focusBorderColor="gray.400"
          borderColor="gray.200"
          color="gray.600"
          type="phone"
          mb="16px"
          placeholder="E-mail"
          onChange={handleEmailChange}
          errorBorderColor="red.300"
          isInvalid={formValues.emailError}
        />
      </InputGroup>
      <Button
        isLoading={loading}
        onClick={handleButtonClick}
        backgroundColor="gray.200"
        color="gray.600"
        width="100%"
        mb="16px"
        _hover={{ boxShadow: '-2px 5px 5px 0px #00000029', transform: 'translateY(-2px)' }}
      >
        Passwort zurücksetzen
      </Button>
      <Flex justifyContent="flex-end">
        <Link color="gray.400" href="/login">
          Zurück zur Anmeldung
        </Link>
      </Flex>
    </Box>
  );
};

export default ForgotPasswordForm;
