import React from 'react';
import { Box, Text, Flex, Heading, Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import AuthComponent from '../../components/AuthComponent';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';

const Login = () => {
  const router = useRouter();

  const isLogin = router.pathname === '/login';
  const forgotPassword = router.pathname === '/forgot-password';

  return (
    <Flex minH="100vh" backgroundColor="reweRot">
      <Box flexGrow="1" marginLeft="200px">
        <Box pt="32px">
          <Heading size="lg" color="white" mb="32px" fontWeight="normal">
            REWE Märkte / PENNY Märkte / Läger / Ausschreibung 2024
          </Heading>
          <Heading size="xl" color="white" lineHeight="55px" fontWeight="normal">
            Bundesweite Entsorgungsdienstleistungen <br /> für REWE-, PENNY-Märkte und Läger
          </Heading>
          <Box mr="64px" mt="64px">
            <Text color="white" as="em" fontSize="20px">
              Zum reibungslosen Betrieb nutzen Sie bitte Chrome als Browser in der neusten Version. Denken sie an Ihre Firewall und die dort
              hinterlegten Beschränkungen und schauen Sie auch in Ihrem Spam- Ordner nach evtl. fehlenden Registrierungsmails oder
              ähnlichem.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box width="20vw">
        <Box
          padding="16px"
          backgroundColor="white"
          borderTopLeftRadius="5px"
          borderBottomLeftRadius="5px"
          mt="32px"
          mb="32px"
          cursor="pointer"
          boxShadow="0px 10px 7px 2px #ab1b1d"
          onClick={() => router.push('/information')}
        >
          Alle Informationen zur aktuellen Ausschreibung
        </Box>
        {forgotPassword ? (
          <ForgotPasswordForm />
        ) : (
          <>
            <AuthComponent isLogin={isLogin} />
            <Box
              padding="16px"
              backgroundColor="white"
              borderTopLeftRadius="5px"
              borderBottomLeftRadius="5px"
              mt="32px"
              mb="32px"
              boxShadow="0px 10px 7px 2px #ab1b1d"
            >
              {isLogin ? (
                <Text>
                  {/* Registrierungen sind zum jetzigen Zeitpunkt nicht möglich. */}
                  Wenn Sie noch kein Konto bei uns haben, können Sie sich hier{' '}
                  <Link fontWeight="600" href="/register">
                    registrieren
                  </Link>
                </Text>
              ) : (
                <Text>
                  Wenn Sie schon ein Konto bei uns haben, können Sie sich hier{' '}
                  <Link fontWeight="600" href="/login">
                    anmelden
                  </Link>
                </Text>
              )}
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default Login;
