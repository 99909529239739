import React, { useState, useEffect, ChangeEvent } from 'react';
import * as yup from 'yup';

import {
  Stack,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  Button,
  useToast,
  Link,
  Heading,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { useAuth } from '../../services/AuthenticationContext';
import { useKeyPress } from '../../config/useEnterClick';

const Auth = ({ isLogin }: { isLogin: boolean }) => {
  const [hasAcceptedTOC, setHasAcceptedTOC] = useState(false);
  const { loading } = useAuth();

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    emailError: false,
    passwordError: false,
  });

  const { signUpWithEmail, signInWithEmail } = useAuth();
  const toast = useToast();
  // const isEnter = useEnterClick();
  const isEnter = useKeyPress('Enter');

  const handleErrors = async () => {
    const { email, password } = formValues;

    if (!email || !password) {
      toast({
        position: 'top-right',
        title: 'Fehler',
        description: 'Bitte füllen Sie alle notwendigen Felder aus!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      setFormValues({ ...formValues, emailError: !email, passwordError: !password });
      return true;
    }

    const isValidEmail = await yup
      .string()
      .email()
      .isValid(email)
      .then((isValid) => {
        if (!isValid) {
          toast({
            position: 'top-right',
            title: 'Fehler',
            description: 'Bitte geben Sie eine valide Emailadresse an!',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });

          setFormValues({ ...formValues, emailError: true, passwordError: false });

          return true;
        }

        return false;
      });

    return isValidEmail;
  };

  const handleRegister = async () => {
    const { email, password } = formValues;

    const hasErrors = await handleErrors();

    if (!hasErrors) {
      signUpWithEmail(email, password);
    }
  };

  const handleLogin = async () => {
    const { email, password } = formValues;

    const hasErrors = await handleErrors();

    if (!hasErrors) {
      signInWithEmail(email, password);
    }
  };

  const handleViewClick = () => setFormValues({ ...formValues, showPassword: !formValues.showPassword });

  const handleEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, emailError: false, email: value });

  const handlePasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, passwordError: false, password: value });

  useEffect(() => {
    if (isEnter) {
      isLogin ? handleLogin() : handleRegister();
    }
  }, [isEnter]);

  return (
    <Box
      padding="16px"
      backgroundColor="white"
      borderTopLeftRadius="5px"
      borderBottomLeftRadius="5px"
      mt="32px"
      mb="32px"
      boxShadow="0px 10px 7px 2px #ab1b1d"
    >
      <Heading size="lg" mb="16px">
        {isLogin ? 'Anmeldung' : 'Registrierung'}
      </Heading>
      <Stack spacing={4}>
        <InputGroup>
          <InputLeftElement>
            <EmailIcon color="gray.400" />
          </InputLeftElement>
          <Input
            variant="outline"
            focusBorderColor="gray.400"
            borderColor="gray.200"
            color="gray.600"
            type="email"
            placeholder="E-mail"
            onChange={handleEmailChange}
            errorBorderColor="red.300"
            isInvalid={formValues.emailError}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftElement>
            <LockIcon color="gray.400" />
          </InputLeftElement>
          <Input
            variant="outline"
            focusBorderColor="gray.400"
            borderColor="gray.200"
            color="gray.600"
            type={formValues.showPassword ? 'text' : 'password'}
            placeholder="Passwort"
            onChange={handlePasswordChange}
            errorBorderColor="red.300"
            isInvalid={formValues.passwordError}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleViewClick}>
              {formValues.showPassword ? <ViewOffIcon /> : <ViewIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
        {isLogin ? null : (
          <Checkbox onChange={() => setHasAcceptedTOC(!hasAcceptedTOC)}>
            <Text color="gray.400" fontSize="12px">
              Ich akzeptiere die Datenschutzerklärung und Geschäftsbedingungen.
            </Text>
          </Checkbox>
        )}
        {isLogin ? (
          <Button
            isLoading={loading}
            onClick={handleLogin}
            backgroundColor="gray.200"
            color="gray.600"
            _hover={{ boxShadow: '-2px 5px 5px 0px #00000029', transform: 'translateY(-2px)' }}
          >
            Anmelden
          </Button>
        ) : (
          <Button
            disabled={!hasAcceptedTOC}
            isLoading={loading}
            onClick={handleRegister}
            backgroundColor="gray.200"
            color="gray.600"
            _hover={{ boxShadow: '-2px 5px 5px 0px #00000029', transform: 'translateY(-2px)' }}
          >
            Registrieren
          </Button>
        )}
        {isLogin ? (
          <Link color="gray.400" href="/forgot-password" textAlign="right">
            Passwort vergessen
          </Link>
        ) : null}
      </Stack>
    </Box>
  );
};

export default Auth;
